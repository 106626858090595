.centeredContainer {
  display: flex;
  align-items: center;
  margin: auto !important;
}

.conversionChanceBase {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: 90%;
  }
}
