.mainLoader {
  margin: auto;
  z-index: 1000;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.MuiCircularProgress-colorPrimary {
  color: #28A3AF !important;
}
