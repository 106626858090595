@import '~@lmig/lmds-react/base';

#app,
#rootDiv {
  min-height: 100%;
  background-color: #bdc1c6;
  overflow: scroll;
}

.lm-app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.routes {
  margin-top: 91px;
  min-width: 450px;
  padding-bottom: 16px;
}
