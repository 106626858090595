#crossSellOpportunitiesCard {
  .crossSellOpportunities {
    margin-left: 32px;
    margin-right: 32px;

    .opportunityColumn {
      display: flex;
      justify-content: center;

      .opportunityIcon {
        pointer-events: none;

        .lm-Icon-label {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}
