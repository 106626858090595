.wrapper {
  min-width: 50%;
  margin: 16px 32px 0 32px;
  padding: 10px 16px 26px 16px;
}

.wrapper.slim {
  padding: 16px 16px;
  overflow-y: auto;
  position: static;
}

.loading {
  background-color: #000000;
  opacity: 0.25;
}

.relative {
  position: relative;
}

.sectionInfo {
  color: #1a1446;
}

.centeredContainer {
  justify-content: center !important;
}

.cardCentered {
  .lm-Heading {
    max-width: 100%;
    margin: 0.5em auto;
  }
}
