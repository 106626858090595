.ccpaMessage {
  .lm-Body,
  .buttons {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .buttons {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
  }
}
